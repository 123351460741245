:root {
  --primary: #D38E4C;
  --primary-dark: #512C0E;
  --primary-light: #FCE890;
  --black: #000000;
  --white: #FFFFFF;
  --proxima: "Proxima";
  --permanent-marker: "PermanentMarker";
  --roboto-light: "Roboto-Light";
  --roboto-regular: "Roboto-Regular";
  --roboto-medium: "Roboto-Medium";
  --roboto-bold: "Roboto-Bold";
  --rockoFLF-regular: "RockoFLF-Regular";
  --rockoFLF-bold: "RockoFLF-Bolt";
  --rockoUltraFLF-regular: "RockoUltraFLF-Regular";
  --rockoUltraFLF-bold: "RockoUltraFLF-Bolt";
}

@font-face {
  font-family: 'Proxima';
  src: url('./font/proxima-nova-soft.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PermanentMarker';
  src: url('./font/PermanentMarker-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('./font/Roboto-Light.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('./font/Roboto-Regular.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('./font/Roboto-Medium.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('./font/Roboto-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RockoFLF-Regular';
  src: url('./font/RockoFLF-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RockoFLF-Bolt';
  src: url('./font/RockoFLF-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RockoUltraFLF-Regular';
  src: url('./font/RockoUltraFLF.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'RockoUltraFLF-Bolt';
  src: url('./font/RockoUltraFLF-Bold.ttf') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  color: var(--white);
  font-size: 18px;
  font-family: var(--proxima);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.logo-top-bar {
  min-width: 200px;
  height: 100px;
}
.main-panel{
  background-color: var(--primary);
}
.navbar-top {
  background-color: var(--primary-dark);
  padding: 15px 0;
  position: relative;
  z-index: 100;
}
.box-presentation-amount {
  margin-left: 32px;
  margin-bottom: 30px;
}
.presentation-amount {
  background-image: url('../assets/img/amountNFT.png');
  width: 450px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  margin: auto;
  z-index: 50;
  position: relative;
}
.presentation-title {
  width: 770px;
  font-family: var(--rockoUltraFLF-bold);
  color: black;
  font-size: 30px;
  margin: auto;
}
.presentation-title-danger {
  color: red;
}
button {
  font-family: var(--bold);
  font-size: 16px;
}
button:focus {
  outline: none!important;
  box-shadow: none!important
}
.img-monkey1 {
  height: 290px;
  width: 450px;
  background-image: url('../assets/img/monkey1.svg');
  background-size: cover;
  position: absolute;
  left: 0; 
  right: 0; 
  top: 0;
  margin-left: auto; 
  margin-right: auto; 
}
.img-monkey2 {
  height: 280px;
  width: 221px;
  background-image: url('../assets/img/monkey2.svg');
  background-size: cover;
  position: absolute;
  top: -17px;
  left: 65%;
}
.footer-img {
  height: 290px;
  width: 450px;
  background-image: url('../assets/img/monkey1.svg');
  background-size: cover;
  margin-top: -80px;
  transform: rotate(180deg);
}
.footer-links {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.footer-box-left {
  text-align: center;
}
.footer-link {
  width: 28px;
  height: 28px;
  opacity: 0.4;
  margin: 0 15px;
}
.footer-title {
  font-family: var(--permanent-marker);
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}
.footer-description {
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  font-size: 14px;
}
.img-feature1 {
  height: 270px;
  width: 350px;
  background-image: url('../assets/img/feature-left.svg');
  background-size: cover;
  position: absolute;
  left: 0; 
  top: -100px; 
  z-index: 50;
}
.img-feature2 {
  height: 320px;
  width: 250px;
  background-image: url('../assets/img/feature-rigth.svg');
  background-size: cover;
  position: absolute;
  right: 0; 
  top: -130px;
  z-index: 50;
}
.navbar-toggler-collapse {
  color: white;
  font-size: 20px;
}
.container-presentation {
  margin-top: 125px;
}
.box-presentation {
  margin-top: 130px;
  position: relative;
  z-index: 40;
  background-image: url('../assets/img/background1.svg');
  width: 100%;
  height: 650px;
  background-size: cover;
  background-position: 50% 73%;
  backdrop-filter: brightness(203%);
  background-position: 50% 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 8px 10px 0px rgb(50 50 50 / 41%);
}
.box-features {
  position: relative;
  min-height: 670px;
  background-image: url('../assets/img/background2.svg');
  width: 100%;
  background-size: cover;
  background-color: white;
  background-position: 50% 56%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.features-title {
  font-family: var(--permanent-marker);
  color: var(--primary-dark);
  font-size: 60px;
  text-shadow: -4px 0 var(--primary-light), 0 4px var(--primary-light), 4px 0 var(--primary-light), 0 -4px var(--primary-light), 6px 10px 0px rgba(0,0,0,0.22); 
  text-align: center;
  margin-bottom: 50px;
  margin-top: -60px;
}
.feature-box {
  min-height: 325px;
  padding: 30px 50px;
  background: rgba(0, 0, 0, 0.54);
  text-align: center;
  border-radius: 16px;
}
.feature-title {
  color: var(--primary-light);
  font-family: var(--rockoUltraFLF-bold);
  font-size: 30px;
  line-height: 35px;
}
.feature-description {
  margin-top: 20px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 800;
}
.mint-background {
  height: 800px;
  width: 100%;
  background-image: url('../assets/img/background3.jpg');
  background-size: cover;
  background-position: 50% 30%;
}
.mint-row {
  height: 800px;
}
.mint-col-left {
  padding: 80px 0 40px 0;
  text-align: center;
}
.mint-state-box {
  margin-bottom: 80px;
}
.mint-state {
  font-family: var(--rockoUltraFLF-bold);
  color: #009B89;
  background-color: #00FFE0;
  padding: 10px 7px;
  font-size: 20px;
  border-radius: 5px;
}
.mint-info {
  color: white;
  font-family: var(--rockoUltraFLF-bold);
  font-size: 30px;
}
.mint-info-disabled {
  color: #F8CEFF;
  font-family: var(--rockoUltraFLF-bold);
  font-size: 30px;
}
.mint-description {
  padding-top: 40px;
  color: white;
  font-family: var(--rockoUltraFLF-bold);
  font-size: 30px;
  width: 475px;
  margin: auto;
}
.mint-button-discord-box {
  margin-top: 80px;
}
.mint-button-discord {
  color: white;
  font-family: var(--permanent-marker);
  font-size: 22px;
  padding: 10px 15px;
  border: 2px solid white;
  text-decoration: none;
}
.mint-button-discord:hover {
  color: white!important;
}
.mint-img {
  position: relative;
  background-image: url('../assets/img/monkey-mint.svg');
  background-size: cover;
  width: 586px;
  height: 523px;
  margin-top: 80px;
}
.mint-col-right {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mint-footer {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: white;
  width: 430px;
  margin: auto;
  font-weight: 800;
  text-align: center;
}
.faq-background {
  background-image: url('../assets/img/background2.svg');
  background-size: cover;
  background-color: white;
  background-position: 50% 56%;
  position: relative;
  padding-top: 100px;
  padding-bottom: 50px;  
}
.faq-description {
  font-family: var(--rockoUltraFLF-bold);
  color: black;
  font-size: 20px;
}
.faq-link {
  font-family: var(--rockoUltraFLF-bold);
  color: #db00ff;
  font-size: 20;
  text-decoration: none;
  overflow-wrap: break-word;
}
.faq-link:hover {
  color: #db00ff;
}
.roadmap-background {
  background-color: #404040;
  position: relative;
  padding-top: 220px;
  padding-bottom: 50px;
}
.roadmap-container {
  position: relative;
  z-index: 50;
}
.roadmap-row-width {
  width: 800px;
  margin: auto;
}
.roadmap-title-xl {
  position: absolute;
  z-index: 30;
  top: 15px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 250px;
  font-family: var(--permanent-marker);
  color: #565656;
  width: fit-content;
}
.roadmap-row {
  display: flex;
  padding-bottom: 90px;
}
.roadmap-img {
  background-image: url('../assets/img/banana.svg');
  height: 77px;
  width: 77px;
}
.roadmap-info {
  margin-left: 40px;
  width: 680px;
}
.roadmap-title {
  font-family: var(--permanent-marker);
  font-size: 40px;
  color: #D38E4C;
  margin-bottom: 25px;
}
.roadmap-description {
  font-family: var(--rockoUltraFLF-bold);
  color: white;
  font-size: 20px;
}
.faq-read-more {
  font-family: var(--rockoUltraFLF-bold);
  font-size: 20px;
  color: black;
  margin-bottom: 40px;
  text-align: center;
}
.faq-discord {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.faq-button-discord {
  background-color: white;
  font-family: var(--permanent-marker);
  color: #000000;
  padding: 10px 15px;
  border: 3px solid black;
  text-decoration: none;
}
.faq-button-discord:hover {
  color: #000000;
}
.roadmap-img-left {
  position: absolute;
  left: 0;
  top: 300px;
  width: 180px;
  height: 278px;
  background-image: url('../assets/img/monkey1-vertical.svg');
  background-size: cover;
}
.roadmap-img-rigth {
  position: absolute;
  right: 0;
  bottom: 100px;
  width: 180px;
  height: 278px;
  background-image: url('../assets/img/monkey1-vertical.svg');
  background-size: cover;
  transform: rotate(180deg);
}
.team-container {
  padding: 60px 0;
  background-color: white;
}
.faq-tile {
  font-family: var(--permanent-marker);
  color: var(--primary-dark);
  font-size: 60px;
  text-shadow: -4px 0 var(--primary-light), 0 4px var(--primary-light), 4px 0 var(--primary-light), 0 -4px var(--primary-light), 6px 10px 0px rgba(0,0,0,0.22); 
  text-align: center;
  margin-bottom: 50px;  
}
.team-tile {
  font-family: var(--permanent-marker);
  color: var(--primary-dark);
  font-size: 60px;
  text-shadow: -4px 0 var(--primary-light), 0 4px var(--primary-light), 4px 0 var(--primary-light), 0 -4px var(--primary-light), 6px 10px 0px rgba(0,0,0,0.22); 
  text-align: center;
  margin-bottom: 50px;
}
.team-row {
  margin-bottom: 50px;
}
.team-col {
  margin-bottom: 30px;
}
.team-box {
  text-align: center;
  cursor: pointer;
}
.team-img {
  width: 243px;
  height: 243px;
  margin: auto;
}
.team-name {
  font-family: var(--permanent-marker);
  font-size: 20px;
  color: black;
  text-decoration: none!important;
}
.nav-item-button {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.btn-connect-wallet-adrress {
  background: transparent;
  margin: 3px;
}
.box-owner {
  padding: 20px 80px;
  background-color: var(--secondary);
}
.input-base-uri {
  background-color: transparent;
  border: 3px solid white;
  color: white;
  padding: 12px;
  font-family: var(--rockoUltraFLF-bold);
  width: 350px;
  margin-right: 15px;
}
.button-mint {
 display: flex;
 flex-direction: column;
 position: absolute;
 top: 297px;
 left: 163px;
 cursor: pointer;
 text-align: center;
}
.button-mint-title {
  font-family: var(--permanent-marker);
  font-size: 44px;
  color: white;
  text-shadow: -4px 0 rgb(24, 24, 24), 0 4px rgb(24, 24, 24), 4px 0 rgb(24, 24, 24), 0 -4px rgb(24, 24, 24), -4px 2px rgb(24, 24, 24), 2px 4px rgb(24, 24, 24), 4px 2px rgb(24, 24, 24), 2px -4px rgb(24, 24, 24), -4px -2px rgb(24, 24, 24), -2px 4px rgb(24, 24, 24), 4px -2px rgb(24, 24, 24), -2px -4px rgb(24, 24, 24); 
}
.button-mint-info {
  font-family: var(--permanent-marker);
  font-size: 22px;
  color: white;
  margin-top: -18px;
  text-shadow: -4px 0 rgb(24, 24, 24), 0 4px rgb(24, 24, 24), 4px 0 rgb(24, 24, 24), 0 -4px rgb(24, 24, 24), -4px 2px rgb(24, 24, 24), 2px 4px rgb(24, 24, 24), 4px 2px rgb(24, 24, 24), 2px -4px rgb(24, 24, 24), -4px -2px rgb(24, 24, 24), -2px 4px rgb(24, 24, 24), 4px -2px rgb(24, 24, 24), -2px -4px rgb(24, 24, 24); 
}
.join-family-box-img {
  display: flex;
}
.join-family-img {
  width: 150px;
  margin-right: 50px;
}
.btn-connect-wallet-adrress:hover {
  background: transparent;
}
.box-arrow-disconect {
  display: flex;
  align-items: center;
  margin-right: 19px;
  font-family: var(--proxima);
  font-size: 15px;
  font-weight: bold; 
  cursor: pointer;
}
.container-wallet-conected {
  display: flex;
  align-items: center;
}
.button-connect {
  color: var(--white);
  background-color: transparent;
  border: 3px solid #FFFFFF;
  padding: 10px 0;
  width: 160px;
  font-family: var(--permanent-marker);
  font-size: 22px;
}
.topbar-link {
  color: var(--white)!important;
  font-family: var(--permanent-marker)!important;
  font-size: 22px !important;
  opacity: 0.4;
  -webkit-font-smoothing: antialiased;
  transition: opacity 0.5s ease-out;
  width: max-content!important;
}
.topbar-link:hover {
  opacity: 1;
}
.topbar-link-active {
  opacity: 1;
}
.header-eyes {
  position: absolute;
  width: 55px;
  bottom: -12px;
  left: 0;
  right: 0;
  margin: auto;
}
.navbar-collapse-top {
  justify-content: space-between;
  align-items: center;
}
.box-join-family {
  background-color: var(--primary);
}
.join-family-row {
  border-top: 10px solid var(--secondary);
  margin-right: 0px;
  padding: 30px 30px 40px 90px;
}
.join-family-col-left {

}
.join-family-col-right {
  display: flex;
  align-items: center;
}
.rewards-buff-monster-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.rewards-buff-monster-img {
  margin-right: 15px;
}
.join-family-title {
  font-size: 24px;
  color: #2b5336;
  font-weight: bold;
}
.join-family-description {
  margin-top: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}
.join-family-timer {
  font-size: 30px;
  padding-left: 100px;
  font-weight: bold;
}
.headquaters-info{
  padding-left: 80px;
  margin-bottom: 20px;
}
.headquaters-img {
  margin-right: 15px;
  margin-bottom: 15px;
}
.headquaters-title{
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
}
.headquaters-copntainer-img {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 80px;
}
.box-mint-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
}
.box-mint-button-number {
  color: var(--primary);
  font-size: 30px;
}
.box-mint-button-amount {
  background-color: white;
  border: none;
  font-size: 30px;
}
/* ----------- */
/* FOOTER */
.footer{
  background-color: var(--primary-dark);
  position: relative;
  min-height: 175px;
  font-size: 16px;
}
.footer-container {
  background-color: var(--primary-dark);
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  justify-content: space-around;
  text-align: center;
  align-items: center;
}

.footer-text{
  display: flex;
  align-items: center;
}
.div-separator {
  margin-left: 100px;
  width: 310px;
  height: 65px;
  background-color: white;
  top: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 400px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: var(--primary);
  font-weight: bold;
  font-size: 18px;
  font-weight: bolder;
  margin-bottom: 50px;
}
.div-footer-empty{
  position: absolute;
  width: 300px;
  height: 20px;
  background-color: white;
  top: 0px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: -100px;
}
.div-footer-title{
  color: var(--primary);
}
/* ------ */
.box-origin{
  background-color: var(--secondary);
  margin-bottom: 10px;
  height: 400px;
}
.origin-text{
  margin-left: 80px;
  width: 50%;
}
/* ------------------------ */
/* GALLERY */
.box-gallery{
  background-color: var(--secondary);
  margin-bottom: 10px;
}
.container-gallery-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 35px;
}
.gallery-box-img {
  height: 350px;
  padding: 80px 60px 0 60px;
  border-radius: 0!important;
}
.gallery-img {
  width: 220px;
  height: 270px;
}
.gallery-img-color1 {
  background-color: #feff7f;
}
.gallery-img-color2 {
  background-color: #5ac2ff;
}
.gallery-img-color3 {
  background-color: #ff8e98;
}
.gallery-img-color4 {
  background-color: #ff58fe;
}
.gallery-img-color5 {
  background-color: #aa5eff;
}
.gallery-img-color6 {
  background-color: #feff7f;
}
.gallery-img-color7 {
  background-color: #5ac2ff;
}
.gallery-img-color8 {
  background-color: #ff8e98;
}
.gallery-img-color9 {
  background-color: #ff58fe;
}
.gallery-img-color10 {
  background-color: #aa5eff;
}
/* ------- */
/* REWARDS */
.box-rewards{

}

.div-rewards-content{
  padding-left: 80px;
  width: 82%;
  font-family: var(--proxima);
  
}
.title-color-rewards{
  color: rgb(216,71,39);
  margin-bottom: 30px;
  font-family: var(--proxima);
}
.div-text-content{
  margin-bottom: 20px;
}
.row-rewards{
  margin-bottom: 20px;
}
.row-rewards .col-6{
  margin-bottom: 50px;
}
/* ------- */
.navbar {
  margin-bottom: 0;
  position:fixed;
  top:0; 
  width:100%;
}
.box-uniqueness {
  background-color: var(--secondary);
  padding-bottom: 40px;
}
.row-uniqueness {
  margin: 0px;
  padding: 0 80px;
}
.col-uniqueness {
  max-width: 400px;
}
.uniqueness-container-icon {

}
.uniqueness-col-width {
  width: 85%;
}
.uniqueness-icon {
  padding: 10px;
  border: 1px solid #FFFFFF;
  border-radius: 5px;
}
.uniqueness-title {
  font-size: 26px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.uniqueness-subtitle {
  font-weight: bold;
}
.uniqueness-li {
  font-weight: bold;
}
.img-florida {
  width: 500px;
  height: 300px;
  margin: 10px 0;
}
.separator-black {
  height: 10px;
  width: 100%;
  background-color: var(--primary);
}
.roapmap-container {
  background-color: var(--secondary);
}
.roadmap-box {
  padding: 0 0 100px 40px;
}
.roadmap-box-width {
  padding-left: 40px;
  width: 900px;
  padding-bottom: 40px;
}
.roadmap-title {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.roadmap-ul {
  padding-left: 180px;
}
.roadmap-li {
  list-style: none;
  font-weight: bold;
  margin-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer {
  padding-top: 0;
  padding-bottom: 0;
}
.title-rewards{
  font-size: 2rem;
  font-weight: bold;
  margin-top: 35px;
}
.box-minting-progress {
  background-color: var(--secondary);
  margin-bottom: 10px;
  font-family: var(--proxima);
}
.content-progress-mintend{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
  font-family: var(--proxima);
  
}
.progress-minted{
  width: 90%;
}
.progress-bar{
  background: linear-gradient(90deg, #02d8f0 1.48%, #02d8f0 26%);
}
.team-box-href {
  text-decoration: none;
}

.a-link{
  display: contents;
}
.a-link:hover{
  text-decoration: underline!important;
}
.img-roadmap {
  width: 152px;
  position: absolute;
  left: -178px;
}
.modal-member {
  max-width: 1200px;
  height: initial;
}
.modal-backdrop {
  background-color: rgba(88, 53, 6, 0.9)!important;
}
.modal-box {
  display: flex;
}
.modal-img {
  background: white;
  max-width: 500px;
  max-height: 500px;
}
.modal-box-rigth {
  max-width: 700px;
  padding-left: 50px;
  padding-top: 30px;
}
.modal-name {
  font-family: var(--permanent-marker);
  color: var(--primary);
  font-size: 40px;
  margin-bottom: 30px;
}
.modal-role {
  font-family: var(--rockoUltraFLF-bold);
  font-size: 24px;
  color: white;
  margin-bottom: 30px;
}
.modal-description {
  font-family: var(--rockoUltraFLF-bold);
  font-size: 20px;
  color: white;
  margin-bottom: 30px;
}
.modal-social {
  font-family: var(--rockoUltraFLF-bold);
  font-size: 20px;
  color: white;
  text-decoration: none;
}
.container-owner {
  background-color: #404040;
  padding: 30px 0 40px 0;
}
.owner-title {
  font-family: var(--rockoUltraFLF-regular);
  font-size: 25px;
  color: #D38E4C;
  margin-bottom: 10px;
}
.button-owner {
  color: white;
  font-family: var(--permanent-marker);
  font-size: 22px;
  padding: 10px 15px;
  border: 3px solid white;
  text-decoration: none;
  background: transparent;
}
@media (max-width: 2039px) {
  .gallery-img-color6 {
    background-color: #aa5eff;
  }
  .gallery-img-color7 {
    background-color: #feff7f;
  }
  .gallery-img-color8 {
    background-color: #5ac2ff;
  }
  .gallery-img-color9 {
    background-color: #ff8e98;
  }
  .gallery-img-color10 {
    background-color: #ff58fe;
  }
}
@media (max-width: 1700px) {
  .gallery-img-color6 {
    background-color: #feff7f;
  }
  .gallery-img-color7 {
    background-color: #5ac2ff;
  }
  .gallery-img-color8 {
    background-color: #ff8e98;
  }
  .gallery-img-color9 {
    background-color: #ff58fe;
  }
  .gallery-img-color10 {
    background-color: #aa5eff;
  }
}
@media (max-width: 1400px) {
  .join-family-row{
    text-align: center;
  }
  .mint-img {
    width: 494px;
    height: 442px;
  }
  .button-mint {
    top: 252px;
    left: 146px;
  }
  .button-mint-title {
   font-size: 38px; 
  }
  .button-mint-info {
    font-size: 17px;
    margin-top: -14px;
  }
}
@media (max-width: 1250px) { 
  .topbar-link {
    font-size: 18px!important;
    margin-right: 0!important;
    margin-left: 0!important;
  }
  .mint-img {
    width: 430px;
    height: 382px;
  }
  .button-mint {
    top: 210px;
    left: 121px;
  }
}

@media (max-width: 1200px) { 
  .img-monkey2 {
    left: 72%;
  }
  .join-family-title{
    display: none;
  }
  .join-family-row{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 90px 30px 100px 30px;
  }
  .join-family-timer {
    padding-left: 0px;
  }
}
@media (max-width: 992px) { 
  .mint-footer {
    margin: 40px auto;
  }
  .mint-row {
    height: auto;
  }
  .mint-background {
    height: auto;
  }
  .button-mint {
    top: 297px;
    left: 163px;
  }
  .button-mint-title {
    font-size: 44px;
  }
  .button-mint-info {
    font-size: 22px;
  }
  .mint-img {
    width: 586px;
    height: 523px;
    margin-top: 40px;
  }
  .modal-img {
    display: flex;
    margin: auto;
  }
  .modal-box {
    display: block;
  }
  .roadmap-title-xl{
    font-size: 65px;
  }
  .presentation-title {
    font-size: 25px;
    width: 330px;
  }
  .box-features {
    margin-top: -8px;
  }
  .features-title {
    margin-top: 30px;
  }
  .feature-box {
    max-width: 450px;
    margin: auto;
    margin-bottom: 25px;
  }
  .img-feature1 {
    display: none;
  }
  .img-feature2 {
    display: none;
  }
  .img-monkey2 {
    display: none;
  }
  .nav-item-button {
    margin-top: 30px;
  }
  .nav-item {
    height: 35px;
  }
  .join-family-box-img {
    justify-content: center;
  }
  .roadmap-box-width {
    padding-left: 40px;
    padding-right: 80px;
    width: auto;
  }
  .box-presentation{
    margin-top: 58px;
  }
}
@media (max-width: 768px) { 
  .mint-img {
    width: 395px;
    height: 347px;
  }
  .button-mint {
    top: 196px;
    left: 108px;
  }
  .button-mint-title {
   font-size: 34px; 
  }
  .button-mint-info {
    font-size: 17px;
  }
  .join-family-col-right {
    justify-content: center;
  }
  .gallery-img {
    width: 260px;
  }
  .roadmap-box-width {
    padding-left: 40px;
    padding-right: 80px;
    width: auto;
  }
  .col-uniqueness{
    width: 100%;
  }
  .uniqueness-col-width{
    width: 100%;
  }
  .col-uniqueness{
    max-width: none;
    margin-bottom: 25px;
  }
  .uniqueness-title{
    margin-bottom: 5px;
    margin-top: 5px;
  }

}
@media (max-width: 576px) {
  .modal-name {
    margin-bottom: 10px;
  }
  .modal-role {
    margin-bottom: 10px;
  }
  .modal-description {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .modal-social {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .modal-box-rigth {
    padding-left: 20px;
    padding-right: 20px;
  }
  .modal-img {
    width: 300px;
    height: 300px;
  }
  .mint-description {
    width: 100%;
    padding: 40px 10px 0px 10px;
  }
  .mint-img {
    width: 350px;
    height: 312px;
    margin-top: 20px;
  }
  .mint-row {
    height: auto;
    min-height: 800px;
  }
  .button-mint {
    top: 174px;
    left: 100px;
  }
  .button-mint-title {
    font-size: 30px;
  }
  .button-mint-info {
    font-size: 14px;
    line-height: 37px;
  }
  .mint-footer {
    width: 100%;
    margin-top: 80px!important;
    margin-bottom: 80px !important;
  }
  .roadmap-background {
    padding-top: 170px;
  }
  .roadmap-img-left{
    display: none;
  }
  .roadmap-img-rigth{
    display: none;
  }
  .roadmap-img {
    height: 50px;
    width: 61px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .roadmap-row-width{
    width: 100%;
  }
  .roadmap-info {
    margin-left: 10px;
    width: 277px;
  }
  .roadmap-title {
    font-size: 30px;  
  }
  .roadmap-description {
    font-size: 16px;
  }
  .team-container {
    padding: 30px 0;
  }
  .faq-tile {
    margin-bottom: 100px;
  }
  .link-forms-responsive{
    width: 289px;
    word-wrap: break-word;
  }
  .footer {
    min-height: 457px;
  }
  .footer-container {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 457px;
    padding-top: 55px;
  }
  .footer-img {
    height: 195px;
    width: 300px;
  }
  .presentation-title {
    font-size: 22px;
    width: 330px;
  }
  .box-presentation-amount {
    margin-left: 0;
    margin-bottom: 0;
  }
  .presentation-amount {
    width: 320px;
    
  }
  .img-monkey1 {
    height: 230px;
    width: 350px;
  }
  .join-family-row {
    padding: 30px 30px 100px 30px;
  }
  .join-family-img {
    margin-right: 0;
    margin: 5px 10px 0 10px;
  }
  .join-family-img-hide {
    display: none;
  }
  .container-gallery-img {
    margin-top: 35px;
  }
  .headquaters-copntainer-img {
    padding: 30px 10px;
    justify-content: center;
  }
  .img-florida {
    height: 200px;
  }
  .roadmap-box {
    padding: 0 0 100px 0px;
  }
  .img-roadmap {
    width: 152px;
    position: initial;
    left: auto;
    margin-bottom: 20px;
  }
  .roadmap-ul {
    padding-left: 0px;
  }
  .roadmap-li {
    flex-direction: column;
    text-align: justify;
    margin-bottom: 50px;
  }

  .div-separator {
    width: 90%;
    height: 48px;
    margin: auto;
    justify-content: center;
    padding-left: 0px;
  }
  .row-uniqueness {
    margin-top: 20px;
    padding: 0 10px;
  }
  .roadmap-box-width {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
  }
  .div-rewards-content {
    margin-top: 20px;
    padding-left: 10px;
    width: auto;
    padding-right: 10px;
  }
  .origin-text {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto; 
  }
  .headquaters-info {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto; 
    padding-left: 0px;
  }


}

.mint-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--rockoUltraFLF-bold);
}

.amount-nfts {
  font-size: 100px;
}

.signs-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.32);
  font-size: 100px;
  border-radius: 60%;
  width: 108px;
  height: 108px;
  margin: 0 60px;
  font-weight: 800;
  cursor: pointer;
  font-family: var(--rockoUltraFLF-bold);
}
.signs-circle:hover {
  cursor: pointer;
}