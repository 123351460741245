<template>
  <nav class="navbar navbar-expand-lg navbar-top">
    <div class="container-fluid px-5 nav-bar-height">
      <div class="nav-bar-left">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fas fa-bars navbar-toggler-collapse"></i>
        </button>
      </div>
      <div class="collapse navbar-collapse navbar-collapse-top" id="navbarNav">
        <div>
          <img
            src="../assets/img/logo-crazy-baboonz.png"
            class="logo-top-bar"
          />
        </div>
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              href="#features"
              @click="activeLink('features')"
              :class="`nav-link my-3 mx-2 topbar-link ${
                currentLink === 'features' ? 'topbar-link-active' : ''
              } `"
              >Features</a
            >
            <img
              v-if="currentLink === 'features'"
              src="../assets/img/eyes.png"
              class="header-eyes"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a
              href="#mintBox"
              @click="activeLink('mint')"
              :class="`nav-link my-3 mx-2 topbar-link ${
                currentLink === 'mint' ? 'topbar-link-active' : ''
              } `"
              >Mint</a
            >
            <img
              v-if="currentLink === 'mint'"
              src="../assets/img/eyes.png"
              class="header-eyes"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a
              href="#roadmap"
              @click="activeLink('roadmap')"
              :class="`nav-link my-3 mx-2 topbar-link ${
                currentLink === 'roadmap' ? 'topbar-link-active' : ''
              } `"
              >Roadmap</a
            >
            <img
              v-if="currentLink === 'roadmap'"
              src="../assets/img/eyes.png"
              class="header-eyes"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a
              href="#team"
              @click="activeLink('team')"
              :class="`nav-link my-3 mx-2 topbar-link ${
                currentLink === 'team' ? 'topbar-link-active' : ''
              } `"
              >The Team</a
            >
            <img
              v-if="currentLink === 'team'"
              src="../assets/img/eyes.png"
              class="header-eyes"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a
              href="#faqs"
              @click="activeLink('faqs')"
              :class="`nav-link my-3 mx-2 topbar-link ${
                currentLink === 'faqs' ? 'topbar-link-active' : ''
              } `"
              >FAQs</a
            >
            <img
              v-if="currentLink === 'faqs'"
              src="../assets/img/eyes.png"
              class="header-eyes"
              alt=""
            />
          </li>
          <li class="nav-item">
            <a
              href="https://twitter.com/CrazyBaboonz"
              target="_blank"
              class="nav-link my-3 mx-2 topbar-link"
            >
              <img src="../assets/img/twiter.png" class="header-icon" alt="" />
            </a>
          </li>
          <li class="nav-item-button">
            <div class="div-connect-to-wallet">
              <div v-if="!wallet" style="z-index: 50">
                <button type="button" class="button-connect" @click="showModal">
                  CONNECT
                </button>
              </div>
              <div
                v-else
                class="container-wallet-conected"
                style="position: relative"
              >
                <button type="button" class="btn btn-connect-wallet-adrress">
                  <span class="btn-connect-wallet-address">{{
                    walletShort
                  }}</span>
                </button>
                <div class="box-arrow-disconect" @click="disconect">
                  DISCONNECT
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <Modal
        v-show="isModalVisible"
        @close="closeModal"
        @openWallet="openWallet"
      />
    </div>
  </nav>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import walletService from "../services/wallet";
import Modal from "./WalletsModal.vue";

export default {
  name: "TopBar",
  components: {
    Modal,
  },
  data() {
    return {
      isModalVisible: false,
      nameWallet: "",
      currentLink: "",
    };
  },
  methods: {
    ...mapActions("wallet", ["setWallet", "disconectWallet"]),
    ...mapActions("collectable", [
      "isTheOwner",
      "setTotalSupply",
      "getBaseURI",
      "getOwner",
      "getProvenanceHash",
      "getWhitelist",
    ]),
    ...mapMutations("collectable", ["emptyCollection"]),
    async openWallet(wallet) {
      this.isModalVisible = false;
      this.nameWallet = wallet;
      let address = await walletService.connect(wallet);
      await this.setWallet({ address });
      await this.getOwner({});
      await this.getBaseURI({});
      await this.getWhitelist({});
      await this.getProvenanceHash({});
      await this.isTheOwner({});
      await this.setTotalSupply({});
    },
    async disconect() {
      if (this.nameWallet === "walletConnect") {
        await walletService.disconnected();
      }
      let address = "";
      this.disconectWallet({ address });
      this.emptyCollection();
      if (this.$route.path === "/admin") {
        this.$router.push("/");
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    activeLink(link) {
      this.currentLink = link;
    },
  },
  computed: {
    ...mapState("wallet", ["wallet"]),
    ...mapState("collectable", ["collectable", "errorMsg"]),
    walletShort() {
      const prefix =
        this.wallet && this.wallet.address && this.wallet.address.toString().slice(0, 6).toUpperCase();
      const length = this.wallet && this.wallet.address && this.wallet.address.toString().length;
      const suffix =
        this.wallet &&
        this.wallet.address &&
        this.wallet.address
          .toString()
          .slice(length - 4, length)
          .toUpperCase();
      return `${prefix}...${suffix}`;
    },
  },
};
</script>

<style scoped>
.nav-item {
  position: relative;
}
.separator {
  position: absolute;
  top: 52%;
}
.router-link-active {
  opacity: 1 !important;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #080e1f;
  color: #7eb5f3;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.gallery-tooltip {
  left: -15px !important;
}

.media-bar {
  align-self: flex-end;
  padding-right: 4rem !important;
  justify-content: flex-end;
  background-color: #000;
}
</style>
